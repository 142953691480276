<template>
  <div id="app">
    <section class="header hero is-bold is-warning has-text-centered" style="margin-bottom:2rem;"><span class="subtitle">Clipper</span>
    </section>

    <section class="container" v-if="!isloggedIn">
      <b-field label="Email" type="is-danger" message="">
          <b-input v-model="email" type="email" maxlength="50" required="true" value="your@email.net"></b-input>
      </b-field>
      <b-field label="Token" type="is-warning" message="">
          <b-input v-model="inputToken" type="password" maxlength="50" required="true" value=""></b-input>
      </b-field>
      <b-button type="is-success" size="is-large" @click="executeAction('login',-1)">Login</b-button>
    </section>

    <section class="results container" v-if="isloggedIn">
      <div class="tile is-ancestor is-vertical">

        <div class="tile is-parent">

          <article class="tile is-child is-6">
            <b-dropdown v-model="pack1select" aria-role="list" style="margin-left:0; margin-right:0.75rem;">
              <button class="button is-success is-large" slot="trigger">
                <span style="text-transform: capitalize;">{{ pack1select }}</span>
                <b-icon icon="angle-down"></b-icon>
              </button>
              <b-dropdown-item aria-role="listitem" v-for="item in table0Data" v-bind:key="item.id" v-bind:value="item.clip"  @click="executeAction(pack1select,1)">
                {{ item.clip }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button type="is-success" size="is-large" @click="table1searchable=!table1searchable" :disabled="pack1select=='Select'"><b-icon icon="filter"></b-icon></b-button>
            <b-button type="is-danger is-pulled-right" size="is-large" v-if="isPhone()" @click="logout()" style="margin-right:0.75rem;"><b-icon icon="power-off"></b-icon></b-button>
          </article>

          <article class="tile is-child is-6" v-if="!isPhone()">
            <b-dropdown v-model="pack2select" aria-role="list" style="margin-left:0; margin-right:0.75rem;">
              <button class="button is-success is-large" slot="trigger">
                <span style="text-transform: capitalize;">{{ pack2select }}</span>
                <b-icon icon="angle-down"></b-icon>
              </button>
              <b-dropdown-item aria-role="listitem" v-for="item in table0Data" v-bind:key="item.id" v-bind:value="item.clip"  @click="executeAction(pack2select,2)">
                {{ item.clip }}
              </b-dropdown-item>
            </b-dropdown>
            <b-button type="is-success" size="is-large" @click="table2searchable=!table2searchable" :disabled="pack2select=='Select'"><b-icon icon="filter"></b-icon></b-button>
            <b-button type="is-danger is-pulled-right" size="is-large" @click="logout()" style="margin-right:0.75rem;"><b-icon icon="power-off"></b-icon></b-button>
          </article>
        </div>

        <div class="tile is-parent">

          <article class="tile is-child is-6" style="padding-right:1em;">
            <b-table :data="table1Data" :columns="table1Columns()" class="fullwidth"
              :mobile-cards="false" :bordered="false" :striped="true" :narrowed="true" :hoverable="false" :loading="isloading" 
              :selected.sync="table1RowSelected"
              @click="tableClick"
              @dblclick="tableDblClick"
            ></b-table>        
          </article>

          <article class="tile is-child is-6" style="padding-right:1em;" v-if="!isPhone()">
            <b-table :data="table2Data" :columns="table2Columns()" class="fullwidth" 
              :mobile-cards="false" :bordered="false" :striped="true" :narrowed="true" :hoverable="false" :loading="isloading" 
              :selected.sync="table2RowSelected"
              @click="tableClick"
              @dblclick="tableDblClick"
            ></b-table>        
          </article>

        </div>

      </div>
    </section>
  </div>
</template>

<script>
import axios from 'axios'
import { isMobileOnly,isIOS } from 'mobile-device-detect';
import { XORCipher } from './xorcipher.js';

export default {
  name: 'app',
  components: {
    //HelloWorld
  },
  data() {
    return {
      debugMode: false,
      appName: "clipper", // Hack
      email: "", // Hack
      inputToken: "", // Hack
      userid: "19640709", // Hack
      isloggedIn: false,      
      API: "", // see created 
      API_created: false,
      baseURL: 'https://api.init.cc/', 
      headers: {
        'API-token': "",
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        },
      body: {
        'query': "",
        'email': "",
        },

      actionCmd: "",
      actionTarget: 0,
      isloading: false,

      table0Data: [], // Available clip-packs
      table1Data: [],
      table2Data: [],
      table1RowSelected: {},
      table2RowSelected: {},
      table1searchable: false,
      table2searchable: false,
      pack1select: "Select",
      pack2select: "Select",
    };
  },
  created: function() {
      //this.url = new URL(window.location.href);
      this.inputToken = XORCipher.decode(this.userid, this.getStorage("api-token",""));
      this.headers['API-token']  = this.inputToken;
      this.email = XORCipher.decode(this.userid, this.getStorage("email",""));
      this.debugAlert("object initial created with: " + this.headers['API-token'] + " " + this.email);
      this.isloggedIn = (this.email!="");
      if (this.isloggedIn) {
        this.debugAlert("load packs in created: " + this.headers['API-token'] + " " + this.email);
        this.executeAction("clip-packs", 0); // Read available packs
      }
},
methods: {

    debugAlert(msg) {
      if (this.debugMode) {
        alert(msg);
      }
    },

    isPhone() {
      return (isMobileOnly || isIOS);
    },

    createAPI () {
        if (this.headers['API-token']) {
          this.API = axios.create({ baseURL: this.baseURL, headers: this.headers}); 
          this.API_created = true; // created: wird mehr als einmal durchlaufen zB wenn Modal benutzt wird
          this.debugAlert("createAPI created with: "+ this.headers['API-token']);        
        } else { 
          this.debugAlert("createAPI: not created, b/c token"); 
        }
    },

    getStorage(nameStr, defaultStr) {
      let v = localStorage.getItem(this.appName + "-" + nameStr);  // returns null if nameStr does not exists
      return (v===null ? defaultStr : v);
    },

    setStorage(nameStr, valueStr) {
      localStorage.setItem(this.appName + "-" + nameStr, valueStr); 
    },

    executeAction(action, actionTarget) {
      this.actionCmd = action;
      this.actionTarget = actionTarget;
      //if (this.action=="login") {
      //  this.$nextTick( function() { this.login(); } )
      //} else if (this.action=="logout") {
      //  this.$nextTick( function() { this.logout(); } )
      //} else {
      this.readDataFromApi(this.actionCmd, this.actionTarget); // nextTick -> result table is not shown anymore
      //}
    },

    table1Columns() {
      return ( 
        [
        { field: 'id',     label: '#',      sortable: true,  numeric: true,   visible: false },
        { field: 'clip',   label: 'Clip',   sortable: true,  numeric: false,  visible: true,  searchable: this.table1searchable },
        { field: 'action', label: 'Action', sortable: false, numeric: false,  visible: false, renderHtml: true },  
        ]
      );
    },

    table2Columns() {
      return ( 
        [
        { field: 'id',     label: '#',      sortable: true,  numeric: true,   visible: false },
        { field: 'clip',   label: 'Clip',   sortable: true,  numeric: false,  visible: true,  searchable: this.table2searchable },
        { field: 'action', label: 'Action', sortable: false, numeric: false,  visible: false, renderHtml: true },  
        ]
      );
    },

    saveCredentials(token, userid, email) { // , nickname
      this.API.defaults.headers['API-token'] = token;
      this.headers['API-token'] = token;
      this.userid = userid;
      this.email = email;
      this.setStorage("userid", userid);
      this.setStorage("api-token", XORCipher.encode(userid, token));
      this.setStorage("email", XORCipher.encode(userid, email));
    },

    logout() {
      this.saveCredentials("", "", ""); 
      localStorage.clear();
      this.API_created = false;
      this.inputToken = "";
      this.email = "";
      this.isloggedIn = false;
      this.table0Data = [];
      this.table1Data = [];
      this.table2Data = [];
      this.pack1select = "Select";
      this.pack2select = "Select";
      this.$buefy.toast.open({
          message: 'You are logged out',
          position: ( this.isPhone() ? 'is-top-right' : 'is-bottom' ),
          type: 'is-success',
          queue: false
      });
    },

    async clipboardCopy(s) {
      await navigator.clipboard.writeText(s);
      this.debugAlert('Copied to: ' + s);
    },

    tableClick(row) {
      this.clipboardCopy(row.clip);
      //alert(row.clip);
    },
    tableDblClick(row) {
      this.tableClick(row);
    },


    readDataFromApi(action, actionTarget) {
      let self = this;
      //self.table1RowSelected = {} 
      if (actionTarget==1) { // not self.actionTarget
        self.table1Data = {};
      } else if (actionTarget==2) { // not self.actionTarget
        self.table2Data = {};
      } else {
        self.table0Data = {};
      }
      self.isloading = true;
      self.body.query = action; // not self.action
      self.body.email = self.email; 
      self.headers['API-token'] = self.inputToken;
      if (!self.API_created) {
          self.createAPI();
      }
      self.debugAlert("readDataFromApi: "+ self.body.query + " " + self.body.email + " " + self.inputToken);
      self.API.post('clipper', self.body)
      .then(function(response) {
        self.isloading = false;
        if (!response.data[0]) {
          self.$buefy.toast.open({
              message: 'No data found',
              position: ( self.isPhone() ? 'is-top-right' : 'is-bottom' ),
              type: 'is-warning',
              queue: false
          });
        } else if (action=="login") { // not self.action
          self.isloggedIn = ( response.data[0].clip=="ok" ) ;
          if (self.isloggedIn) {
            self.saveCredentials(self.inputToken, self.userid, self.email);
            self.$nextTick( function() { self.executeAction("clip-packs", 0); } );
          } else {
            self.logout();
          }
        } else if (actionTarget==1) { // not self.actionTarget
          self.table1Data = response.data;
        } else if (actionTarget==2) { // not self.actionTarget
          self.table2Data = response.data;
        } else {
          self.table0Data = response.data;
        }
      })
       // eslint-disable-next-line
      .catch( function(response) {  // TODO
        self.isloading = false;
        // alert("Error - No data found: " + JSON.stringify(response));
        self.$buefy.toast.open({
            message: 'Could not read data',
            position: ( self.isPhone() ? 'is-top-right' : 'is-bottom' ),
            type: 'is-danger',
            queue: false
        });
        // alert("Error - Could not read report data: " + JSON.stringify(response));
      });
    },

  }
}
</script>

<style>
#app {
  margin-top: 0;
}

</style>
